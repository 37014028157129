export default {
  dashboard: 'Dashboard',
  activityUnderSunTitle: 'Total activity & Activity under sun',
  totalActivity: 'Total activity',
  activityUnderSun: 'Activity under sun',
  xPersons: '{x} person(s)',
  lastHum: 'Last humidity',
  lastTemp: 'Last temperature',
  lastUV: 'Last UV',
  lastUVDose: 'Last UV dose',
  uvAverage: 'UV average',
  heatmap: 'heatmap',
  UvAlerts: 'UV dose notifications',
  uvDoseAverage: 'UV dose average',
  uvDose: 'UV dose',
  start: 'Start',
  onlineUv: 'Online UV',
  UVPrediction: 'UV Prediction',
  activeUserCount: 'Active user count',
  sunScreenNotifications: 'Sun screen notifications',
  percent: 'Percent',
  title: 'title',
  end: 'End',
  uvIndex: 'UV index',
  userNotificationsTitle: 'User notifications',
  onlineUsers: 'Online users'
}
