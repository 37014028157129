export default {
  500: 'Internal server error',
  USERNAME_PASSWORD_NOT_MATCH: 'Username or password is not correct',
  USER_NOT_FOUND: 'User not found',
  BAD_REQUEST: 'Bad request',
  INVALID_DATA_TYPE: 'Invalid data type',
  USERNAME_ALREADY_EXIST: 'Username already exist',
  ILLEGAL_ARGUMENT: 'Illegal argument',
  ILLEGAL_REQUEST: 'Illegal request',
  CHILD_RECORD_FOUND: 'Unable to delete',
  SESSION_EXPIRED: 'Session expired',
  VALIDATION_EXCEPTION: 'Validation exception',
  ACCESS_DENIED: 'Access denied',
  SERVER_ERROR: 'Internal server error',
  DUPLICATE_REQUEST: 'Duplicate request',
  LOGICAL_UN_AUTHORIZED: 'Logical un authorized',
  USER_NOT_ACTIVE: 'User not active',
  MOBILE_NOT_CONFIRM: 'Mobile not confirm',
  DATA_NOT_FOUND: 'Data not found',
  INVALID_TOKEN_HEADER: 'You are logout from your account',
  TOKEN_VERIFICATION_EXPIRED: 'Token verification expired',

  7077: 'Device is assigned to other user',
  3101: 'Username (mobile/email) already exist',
  27004: 'User is not a member of branch',
  1104: 'Username is invalid',
  4108: 'Duplicate unique Id',
  547570: 'Organization or working site not found',
  36564: 'registration Request already sent',
  27009: 'working site not belong to organization',
  27002: 'organization must be branch',
  1085: 'Mobile is invalid',
  3031: 'Code is invalid'
}
