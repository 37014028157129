export default {
  couponRemoved: 'کوپن حذف شد',
  couponUpdated: 'کوپن به روز رسانی شد',
  couponAdded: 'کوپن افزوده شد',
  coupon: 'کوپن',
  used: 'استفاده شده',
  pin: 'پین',
  availableFrom: 'از',
  availableUntil: 'تا',
  reusable: 'قابل استفاده',
  discountType: 'نوع تخفیف',
  claimLimit: 'محدودیت استفاده'
}
