import store from '../store'
import config from '@/config'
import moment from 'moment-jalaali'
import 'moment-timezone'

export function toZone(date, format = 'YYYY/M/D HH:mm:ss') {
  if (!date) return null
  return moment.utc(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(format)
}

export function today() {
  return moment.utc().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).startOf('day').toISOString()
}

export function now() {
  return moment.utc().toISOString()
}

export function toJalali(date, format = 'jYYYY/jM/jD HH:mm:ss') {
  if (!date) return null
  return moment.utc(date).tz('Asia/Tehran').format(format)
}

export function isObject(o) {
  return typeof o === 'object' && o !== null && o.constructor && Object.prototype.toString.call(o).slice(8, -1) === 'Object'
}

export function extend(target, src) {
  const noExtend = ['__proto__', 'constructor', 'prototype']
  Object.keys(src)
    .filter((key) => noExtend.indexOf(key) < 0)
    .forEach((key) => {
      if (typeof target[key] === 'undefined') target[key] = src[key]
      else if (isObject(src[key]) && isObject(target[key]) && Object.keys(src[key]).length > 0) {
        extend(target[key], src[key])
      } else {
        target[key] = src[key]
      }
    })
  return target
}

export function isEmpty(value) {
  if (isObject(value)) {
    return Object.keys(value).length === 0
  }
  return value == null || value.length === 0
}

export function mobileNormalize(mobile) {
  if (!mobile) return null
  else if (mobile.startsWith('+98')) {
    return mobile
  } else if (mobile.startsWith('0098')) {
    return `+${mobile.substr(2)}`
  } else if (mobile.startsWith('0')) {
    return `+98${mobile.substring(1)}`
  } else if (mobile.startsWith('9')) {
    return `+98${mobile}`
  } else return mobile
}

export function mobileDenormalize(mobile) {
  if (!mobile) return null
  else if (mobile.startsWith('+98')) {
    return `0${mobile.substr(3)}`
  } else if (mobile.startsWith('0098')) {
    return `0${mobile.substr(4)}`
  } else if (mobile.startsWith('0')) {
    return mobile
  } else if (mobile.startsWith('9')) {
    return `0${mobile}`
  } else return mobile
}

export function hasPermission(permission, allPermissions = store.getters.permissions) {
  if (isEmpty(permission) || isEmpty(allPermissions)) return false
  return allPermissions.includes(permission)
}

export function clockToMinute(clock) {
  let [hours, minutes] = clock.split(':')
  hours = isNaN(+hours) ? 0 : +hours
  minutes = isNaN(+minutes) ? 0 : +minutes
  const clockInMinutes = hours * 60 + minutes
  return clockInMinutes
}
