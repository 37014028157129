const translations = {
  dashboard: {
    self: 'Dashboard',
    operation: 'Operations'
  },
  identity: {
    self: 'Identity',
    permissions: 'Permissions',
    roles: 'Roles'
  },
  organization: {
    self: 'Organization',
    operation: 'Operations'
  },
  message: {
    self: 'Message',
    operation: 'Operations'
  },
  alert: {
    self: 'Alert',
    operation: 'Operations'
  },
  device: {
    self: 'Device',
    operation: 'Operations'
  },
  'working-site': {
    self: 'Working Site',
    operation: 'Operations'
  },
  'user-management': {
    self: 'User Management',
    operation: 'Operations'
  },
  'user-register-request': {
    self: 'User Register Request',
    operation: 'Operations'
  },
  admin: {
    self: 'Admin',
    info: 'Basic Info'
  }
}
export default {
  ...translations,
  org: {
    self: 'Organization',
    info: 'Basic Info',
    ...translations
  }
}
