export default {
  devices: {
    deviceRemoved: 'دستگاه حذف شد',
    deviceUpdated: 'دستگاه به روز رسانی شد',
    deviceAdded: 'دستگاه افزوده شد',
    assignToUser: 'تخصیص به کاربر',
    deviceAssigned: 'دستگاه به کاربر اختصاص داده شد',
    deviceUnassigned: 'اختصاص دستگاه از کاربر گرفته شد',
    deviceConfigs: 'تنظیمات دستگاه',
  }
}
