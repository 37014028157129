import ApiCore from './api-core'

export default class ApiBase extends ApiCore {
  getAll(params) {
    return this._request({
      method: 'GET',
      params
    })
  }

  getAllInfo(params) {
    return this._request({
      action: 'info',
      method: 'GET',
      params
    })
  }

  async getInfoById(id, params = {}) {
    params.id = id
    return this._request({
      action: 'info',
      method: 'GET',
      params
    }).then((res) => {
      if (res && res.length) return res[0]
      const error = new Error('Not found')
      error.status = 404
      error.errorCode = 1201
      throw error
    })
  }

  getById(id, params) {
    return this._request({
      method: 'GET',
      action: encodeURIComponent(id),
      params
    })
  }

  create(body) {
    return this._request({
      method: 'POST',
      body
    })
  }

  update(id, body) {
    return this._request({
      action: encodeURIComponent(id),
      method: 'PUT',
      body
    })
  }

  delete(id, params) {
    return this._request({
      method: 'DELETE',
      action: encodeURIComponent(id),
      params
    })
  }

  count(params) {
    return this._request({
      action: 'count',
      method: 'GET',
      params
    })
  }
}
