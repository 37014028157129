const translations = {
  dashboard: 'داشبورد',
  identity: {
    self: 'identity',
    permissions: 'دسترسی ها',
    roles: 'نقش ها'
  },
  organization: {
    self: 'سازمان',
    operation: 'عملیات ها'
  },
  'working-site': {
    self: 'سایت کاری',
    operation: 'عملیات ها'
  },
  'user-management': {
    self: 'مدیریت کاربران',
    operation: 'عملیات ها'
  },
  'user-register-request': {
    self: 'مدیریت درخواست های ثبت نام',
    operation: 'عملیات ها'
  }
}
export default {
  ...translations,
  org: {
    self: 'سازمان',
    info: 'اطلاعات پایه',
    ...translations
  }
}
