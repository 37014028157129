import account from './account'
import addresses from './addresses'
import branches from './branches'
import branding from './branding'
import coupons from './coupons'
import dashboard from './dashboard'
import devices from './devices'
import errors from './errors'
import nav from './nav'
import organizations from './organizations'
import paymentConfigs from './paymentConfigs'
import payments from './payments'
import permissions from './permissions'
import realms from './realms'
import roles from './roles'
import shared from './shared'
import users from './users'
import validations from './validations'
import workingSites from './workingSites'
import alerts from './alerts'
import registerRequests from './registerRequests'

export default {
  alerts,
  registerRequests,
  account,
  addresses,
  branches,
  branding,
  coupons,
  dashboard,
  devices,
  errors,
  nav,
  organizations,
  paymentConfigs,
  payments,
  permissions,
  realms,
  roles,
  shared,
  users,
  validations,
  workingSites
}
