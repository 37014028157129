<template>
  <div>
    <div v-if="label">{{ label }}</div>
    <v-sheet outlined class="pa-3">
      <ValidationObserver ref="ValidationObserver" v-slot="{handleSubmit, invalid}" class="d-flex" tag="div">
        <c-input
          v-model="newTitle"
          class="flex-grow-1 me-3"
          :label="$t('shared.title')"
          inner-label
          dir="auto"
          rules="alpha_dash"
          hide-details="auto"
          @keydown.enter="handleSubmit(addNewItem)"
        />
        <v-btn elevation="0" color="success" :disabled="invalid" @click="handleSubmit(addNewItem)">
          <v-icon dense>mdi-plus</v-icon>
          {{ $t('shared.add') }}
        </v-btn>
      </ValidationObserver>

      <v-divider class="my-5" />

      <v-row dense v-if="!$isEmpty(keys)">
        <v-col v-for="(key, index) in keys" :key="key + index" cols="12" sm="6" md="4">
          <c-input
            v-model="value[key]"
            :label="key"
            inner-label
            hide-details
            dir="auto"
            append-icon="mdi-delete"
            @click:append="removeItem(key)"
          />
        </v-col>
      </v-row>

      <v-alert v-else type="warning" outlined dense class="mb-0">
        {{ $t('shared.noRecords') }}
      </v-alert>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: 'ObjectEditor',
  props: {
    label: {type: String, default: null},
    value: {type: Object, default: () => ({})}
  },
  data() {
    return {
      newTitle: null
    }
  },
  computed: {
    keys() {
      return Object.keys(this.value)
    }
  },
  methods: {
    removeItem(key) {
      const newObject = {...this.value}
      delete newObject[key]
      this.$emit('input', newObject)
    },
    addNewItem() {
      const title = this.newTitle && this.newTitle.trim()
      const isDuplicate = this.keys.includes(title)
      if (!title || isDuplicate) {
        return
      }
      const newObject = {...this.value}
      newObject[this.newTitle] = null
      this.newTitle = null
      this.$refs.ValidationObserver.reset()
      this.$emit('input', newObject)
    }
  }
}
</script>
