export default {
  actions: 'عملیات',
  active: 'فعال',
  add: 'افزودن',
  search: 'جستجو',
  zipCode: 'کد پستی',
  min: 'حداقل',
  max: 'حداکثر',
  export: 'خروجی اکسل',
  all: 'همه',
  accept: 'قبول کردن',
  reject: 'رد کردن',
  online: 'آنلاین',
  lastModified: 'آخرین تغییرات',
  selectAll: 'انتخاب همه',
  free: 'رایگان',
  daily: 'روزانه',
  comment: 'دیدگاه',
  fullName: 'نام و نام خانوادگی',
  date: 'تاریخ',
  chooseDate: 'انتخاب تاریخ',
  description: 'توضیحات',
  noRecordToExport: 'هیچ رکوردی برای خروجی گرفتن وجود ندارد',
  cancel: 'لغو',
  modified: 'اصلاحیه',
  number: 'عدد',
  video: 'ویدیو',
  address: 'آدرس',
  inactive: 'غیرفعال',
  minutes: 'دقیقه',
  permissions: 'دسترسی ها',
  exit: 'خروج',
  price: 'قیمت',
  discount: 'تخفیف',
  exportExcel: 'خروجی اکسل',
  discountType: 'نوع تخفیف',
  applyFilter: 'اعمال فیلتر',
  areYouSureToRemove: 'آیا می خواهید این آیتم را حذف کنید؟',
  areYouSureToDo: 'آیا از انجام این کار اطمینان دارید؟',
  choose: 'انتخاب کنید',
  chooseFile: 'فایل را انتخاب کنید',
  confirmPassword: 'تایید رمز عبور',
  delete: 'حذف',
  submit: 'ثبت',
  percent: 'درصد',
  selectAddress: 'انتخاب آدرس',
  close: 'بستن',
  amount: 'مبلغ',
  value: 'مقدار',
  created: 'تاریخ ایجاد',
  createdMin: 'تاریخ ایجاد از',
  createdMax: 'تاریخ ایجاد تا',
  IRT: 'تومان',
  IRR: 'ریال',
  basicInfo: 'اطلاعات اولیه',
  fromCreated: 'تاریخ ایجاد از',
  toCreated: 'تاریخ ایجاد تا',
  image: 'تصویر',
  economicCode: 'کد اقتصادی',
  edit: 'ویرایش',
  email: 'ایمیل',
  firstName: 'نام',
  lastName: 'نام خانوادگی',
  logo: 'لوگو',
  mobile: 'موبایل',
  youLogouted: 'از حساب کاربری خارج شدید',
  done: 'انجام شده',
  status: 'وضعیت',
  canceled: 'باطل شده',
  clock: 'ساعت',
  createdDate: 'تاریخ ایجاد',
  name: 'نام',
  nationalCode: 'کد ملی',
  nationalId: 'شماره شناسنامه',
  networkError: 'خطا در اتصال به شبکه',
  no: 'خیر',
  noRecords: 'موردی برای نمایش وجود ندارد',
  password: 'رمز عبور',
  phone: 'تلفن',
  plate: 'پلاک',
  return: 'بازگشت',
  save: 'ذخیره',
  settings: 'تنظیمات',
  title: 'عنوان',
  unit: 'واحد',
  username: 'نام کاربری',
  verified: 'تایید شده',
  view: 'مشاهده',
  warning: 'هشدار',
  sms: 'پیامک',
  yes: 'بله',
  uniqueId: 'شناسه یکتا',
  oldPassword: 'رمز عبور فعلی',
  otpSendedToYourMobile: 'رمز یکبار مصرف به موبایل شما ارسال شد',
  newPassword: 'رمز عبور جدید',
  forLoginEnterYourUsername: 'برای ورود موبایل خود را وارد کنید',
  forLoginEnterYourEmail: 'برای ورود ایمیل خود را وارد کنید',
  code: 'کد',
  type: 'نوع',
  fax: 'فکس',
  assign: 'اختصاص',
  unassign: 'عدم اختصاص',
  pageNotFound: 'متاسفانه صفحه مورد نظر یافت نشد',
  tryAgain: 'چند لحظه دیگر دوباره تلاش کنید',
  returnHome: 'بازگشت به صفحه اصلی',
  toResendAgain: 'تا ارسال دوباره',
  enterYourMobileNumber: 'شماره موبایل خود را وارد کنید',
  hello: 'سلام',
  editMobile: 'اصلاح شماره',
  location: 'لوکیشن',
  day: 'روز',
  country: 'کشور',
  province: 'استان',
  male: 'مرد',
  female: 'زن',
  city: 'شهر',
  district: 'منطقه',
  month: 'ماه',
  week: 'هفته',
  times: 'بار',
  year: 'سال',
  android: 'اندروید',
  web: 'وب',
  details: 'جزئیات',
  ios: 'ios',
  sortOrder: 'ترتیب نمایش'
}
