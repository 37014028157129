export default {
  paymentConfigRemoved: 'روش پرداخت حذف شد',
  paymentConfigUpdated: 'روش پرداخت به روز رسانی شد',
  paymentConfigAdded: 'روش پرداخت افزوده شد',
  paymentConfig: 'روش پرداخت',
  PASARGAD_IPG: 'درگاه پاسارگاد',
  CASH: 'نقدی',
  MELLAT_IPG: 'درگاه ملت',
  PARSIAN_IPG: 'درگاه پارسیان',
  ZARRINPAL_IPG: 'درگاه زرین پال',
  SAMAN_IPG: 'درگاه سامان',
  KIPO_IPG: 'درگاه کیپو',
  ISIRANCREDIT_IPG: 'درگاه ایسیران کریدیت',
  ISIRANDEBIT_IPG: 'درگاه ایسیران دیبیت',
  PAYPOD_IPG: 'درگاه پی پاد',
  CAFE_BAZAR_IPG: 'درگاه کافه بازار',
  VANDAR_IPG: 'درگاه وندار',
  SEPPAYMENT_MPG: 'درگاه موبایلی سپه',
  PASARGAD_POS: 'پوز پاسارگاد',
  PARSIAN_POS: 'پوز پارسیان',
  SAMAN_POS: 'پوز سامان',
  IPG: 'درگاه پرداخت مستقیم',
  MPG : 'درگاه پرداخت موبایل',
  POS: 'پوز',
  CASH: 'نقدی',
  ORG: 'درگاه پرداخت سازمانی',
  handlerType: 'نوع روش پرداخت',
  payType: 'نوع پرداخت',
  bankAccount: 'حساب بانکی',
  minimumPayable: 'حداقل پرداخت',
  merchantId: 'شناسه پذیرنده',
}
