import ApiBase from './api-base'

export default class Coupons extends ApiBase {
  constructor(store) {
    const path = 'admin/financial/coupons'
    super(path, store)
  }

  export(params) {
    return this._request({
      action: 'export',
      method: 'GET',
      params
    })
  }
}
