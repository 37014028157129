import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import routes from './routes'
import middlewares from './middlewares'

Vue.use(Meta)
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: routes
})

middlewares(router)

export default router
