export default {
  organizationRemoved: 'Organization removed',
  organizationUpdated: 'Organization updated',
  organizationAdded: 'Organization added',
  organizations: 'Organizations',
  organization: 'Organization',
  organizationId: 'Organization ID',
  fax: 'Fax',
  chooseOrganization: 'Choose organization',
  editOrgInfo: 'Edit organization info'
}
