export default {
  registerRequestRemoved: 'درخواست ثبت نام حذف شد',
  registerRequestUpdated: 'درخواست ثبت نام به روزرسانی شد',
  registerRequestAdded: 'درخواست ثبت نام اضافه شد',
  registerRequests: 'درخواست های ثبت نام',
  registerRequest: 'درخواست ثبت نام',
  registerRequestId: 'شناسه درخواست ثبت نام',
  registerRequestAccepted: 'درخواست ثبت نام تایید شد',
  registerRequestRejected: 'درخواست ثبت نام رد شد',
  status: {
    PROCESSING: 'در جریان',
    ACCEPTED: 'تایید شده',
    REJECTED: 'رد شده'
  }
}
