export default {
  userRemoved: 'کاربر حذف شد',
  userUpdated: 'کاربر به روز رسانی شد',
  userAdded: 'کاربر افزوده شد',
  user: 'کاربر',
  gender: 'جنسیت',
  fieldOfStudy: 'رشته تحصیلی',
  freeConsultation: 'نیازمند مشاوره رایگان هستم',
  birthDate: 'تاریخ تولد',
  mobileConfirmed: 'تایید موبایل',
  twoFactorEnabled: 'ورود دو مرحله ای',
  academicYear: 'سال تحصیلی',
  roles: 'نقش ها',
  password: 'رمز عبور',
  skinType: 'رنگ پوست',
  userRoles: 'تقش های {name}',
  passwordConfirm: 'تایید رمز عبور',
  userAddedToOrganization: 'کاربر به سازمان اضافه شد',
  userAddedToWorkingSite: 'کاربر به سایت کاری اضافه شد',
  addNewUser: 'افزودن کاربر جدید',
  rolesInScope: 'نقش ها در {scope}',
  editUserRoles: 'ویرایش نقش های کاربر',
  userRolesUpdated: 'نقش های کاربر به روز رسانی شد',
  userRemoveFromWorkingSite: 'کاربر از سایت کاری حذف شد',
  userRemoveFromOrganization: 'کاربر از سازمان حذف شد'
}
