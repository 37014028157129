export default {
  bodyReport: 'Body report',
  totalScore: 'Total score',
  HEAD: 'Head',
  FACE: 'Face',
  EARS: 'Ears',
  CHEST: 'Chest',
  ELBOW: 'Elbow',
  NECK: 'Neck',
  SHOULDER: 'Shoulder',
  FOREARM: 'Forearm',
  HAND: 'Hand',
  LEG: 'Leg',
  WAIST: 'Waist',
  SHIN: 'Shin',
  size: 'Size',
  texture: 'Texture',
  color: 'Color',
  mel: 'MEL',
  bbc: 'BBC',
  nv: 'NV',
  commentUpdated: 'Comment updated',
  totalNumberMoles: 'Total Number Moles',
  redPointCount: 'Red: Serious',
  yellowPointCount: 'Yellow: Not Serious',
  greenPointCount: 'Green: Healthy',
  HEAD_Report: 'Head report',
  FACE_Report: 'Face report',
  EARS_Report: 'Ears report',
  CHEST_Report: 'Chest report',
  ELBOW_Report: 'Elbow report',
  NECK_Report: 'Neck report',
  SHOULDER_Report: 'Shoulder report',
  FOREARM_Report: 'Forearm report',
  HAND_Report: 'Hand report',
  LEG_Report: 'Leg report',
  WAIST_Report: 'Waist report',
  SHIN_Report: 'Shin report'
}
