const notifyConfig = {
  position: 'bottom-right',
  timeout: 1500
}
import {hasPermission, isObject, isEmpty} from '@/lib/utils'
export default {
  install: function (Vue) {
    Vue.mixin({
      data() {
        return {
          initializing: true,
          progressing: false
        }
      },
      computed: {
        $booleanOptions() {
          return [
            {text: this.$t('shared.yes'), value: true},
            {text: this.$t('shared.no'), value: false}
          ]
        }
      },
      methods: {
        $setBreadcrumbs(breadcrumbs) {
          if (!isEmpty(breadcrumbs)) {
            breadcrumbs = breadcrumbs.reduce(
              (total, item) => {
                item.exact = true
                return total.concat(item)
              },
              [{to: this.$config.homeUrl, text: this.$t('branding.appTitle'), exact: true}]
            )
          }

          this.$store.commit('setBreadcrumbs', breadcrumbs)
        },
        // ******************** Notifications *******************
        $showSuccess(body, go) {
          this.progressing = false
          this.$goToParent(go)
          return this.$dialog.notify.success(body, notifyConfig)
        },
        $showError(err, {errorInfo, text} = {}) {
          this.progressing = false
          const body = text || this.$errorMessage({err, errorInfo})
          return this.$dialog.notify.error(body, notifyConfig)
        },
        $showInfo(body) {
          return this.$dialog.notify.info(body, notifyConfig)
        },
        $isEmpty(value) {
          return isEmpty(value)
        },
        $showWarning(text, title) {
          const config = {
            persistent: true,
            titleClass: 'text--warning',
            actions: {
              true: {
                text: this.$t('shared.yes'),
                color: 'success'
              }
            },
            text: text,
            title: title || this.$t('shared.warning')
          }
          return this.$dialog.warning(config)
        },
        $confirm(text, title) {
          const confirmConfig = {
            persistent: true,
            titleClass: 'text--warning',
            actions: {
              false: this.$t('shared.no'),
              true: {
                text: this.$t('shared.yes'),
                color: 'error'
              }
            },
            text: text || this.$t('shared.areYouSureToRemove'),
            title: title || this.$t('shared.warning')
          }
          return this.$dialog.confirm(confirmConfig)
        },
        $errorMessage({err, errorInfo}) {
          if (err instanceof TypeError) return err.message
          const errorStatus = err.status
          const errorData = err.data && (err.data[0] || err.data)
          const errorException = errorData && errorData.error
          const errorCode = errorData && errorData.errorCode
          if (typeof errorInfo === 'string') errorInfo = null

          if (this.$te(`errors.${errorCode}`)) {
            return this.$t(`errors.${errorCode}`, errorInfo)
          } else if (this.$te(`errors.${errorException}`)) {
            return this.$t(`errors.${errorException}`)
          } else if (this.$te(`errors.${errorStatus}`)) {
            return this.$t(`errors.${errorStatus}`)
          } else return err
        },
        $goToParent(go) {
          if (go) {
            return this.$router.push(go)
          }
          if (this.$route.meta.label === 'add') {
            const path = this.$route.path
            const routeParams = this.$route.params
            const realize = function (params, route) {
              Object.keys(params).forEach((i) => {
                route = route.replace(`:${i}`, params[i])
              })
              return route
            }
            const matched = this.$route.matched.find((m) => {
              return realize(routeParams, m.path) == path
            })
            let parentPath = matched && matched.parent && matched.parent.path
            if (parentPath) {
              parentPath = realize(routeParams, parentPath)
              this.$router.push(parentPath)
            }
          }
        },
        // ******************** Utils *******************
        $hasPermission(p) {
          return hasPermission(p, this.$store.getters.permissions)
        },
        $filter(search = this.search) {
          const filter = {}
          Object.keys(search).forEach((key) => {
            if (isObject(search[key])) {
              if (search[key].min) filter[`${key}Min`] = search[key].min
              if (search[key].max) filter[`${key}Max`] = search[key].max
            } else if (!isEmpty(search[key])) {
              filter[key] = search[key]
            }
          })
          return filter
        },
        $scrollToTarget(target) {
          if (!target) console.error('No target Determinated')
          this.$nextTick(() => {
            const errorMessages = document.querySelector(target)
            if (!errorMessages) console.error('Target Not Found')
            const headerOffset = 130
            const elementPosition = errorMessages.getBoundingClientRect().top + window.scrollY
            const offsetPosition = elementPosition - headerOffset
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            })
          })
        },
        $preSubmit(ref) {
          const validations = [this.$validator.validateAll()]
          if (ref) {
            const refs = this.$refs[ref]
            if (refs && Array.isArray(refs)) {
              refs.forEach((i) => {
                validations.push(i.$validator.validateAll())
              })
            } else if (refs && refs.$validator) {
              validations.push(refs.$validator.validateAll())
            }
          }
          return Promise.all(validations).then((isValidArray) => {
            const areValid = isValidArray.every((i) => i)
            // if (!areValid) {
            //   this.$scrollToTarget('.v-messages__message')
            // }
            this.progressing = areValid
            return areValid
          })
        }
      }
    })
  }
}
