import ApiBase from '../api-base'

export default class Permissions extends ApiBase {
  constructor(store) {
    const path = 'org/permissions'
    super(path, store)
  }

  getAll(params) {
    return this._request({
      method: 'GET',
      params,
      noAppend: true
    })
  }
}
