<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import PageLayout from '@/layout/index.vue'
export default {
  name: 'App',
  components: {
    PageLayout
  },
  computed: {
    layout() {
      if (!this.$route.meta.layout) {
        return 'PageLayout'
      }
      return 'div'
    }
  },
  metaInfo() {
    const meta = {
      title: null,
      link: [{rel: 'favicon', href: 'favicon/favicon.ico'}]
    }
    const baseTitle = this.$t('branding.appTitle')
    const currentTitle = null // this.$t(`nav.${this.$route.meta.title}`)
    if (!currentTitle) {
      meta.title = baseTitle
      return meta
    }
    meta.title = `${currentTitle} | ${baseTitle}`
    return meta
  }
}
</script>
