export default {
  actions: 'Actions',
  active: 'Active',
  add: 'Add',
  search: 'Search',
  zipCode: 'Zip code',
  min: 'Min',
  max: 'Max',
  export: 'Export',
  all: 'All',
  online: 'Online',
  accept: 'Accept',
  reject: 'Reject',
  lastModified: 'Last modified',
  selectAll: 'Select all',
  free: 'Free',
  daily: 'Daily',
  description: 'Description',
  cancel: 'Cancel',
  comment: 'Comment',
  date: 'Date',
  number: 'Number',
  modified: 'Modified',
  chooseDate: 'Choose date',
  areYouSureToDo: 'Are you sure to do',
  assign: 'Assign',
  noRecordToExport: 'No record to export',
  unassign: 'Unassign',
  video: 'Video',
  fax: 'Fax',
  address: 'Address',
  inactive: 'Inactive',
  minutes: 'Minutes',
  price: 'Price',
  discount: 'Discount',
  exportExcel: 'Export excel',
  discountType: 'Discount type',
  applyFilter: 'Apply filter',
  areYouSureToRemove: 'Are you sure to remove?',
  choose: 'Choose',
  chooseFile: 'Choose file',
  confirmPassword: 'Confirm password',
  delete: 'Delete',
  submit: 'Submit',
  percent: 'Percent',
  close: 'Close',
  amount: 'Amount',
  value: 'Value',
  uniqueId: 'Unique id',
  IRR: 'IRR',
  IRT: 'IRT',
  image: 'Image',
  edit: 'Edit',
  email: 'Email',
  firstName: 'First name',
  logo: 'Logo',
  created: 'Created',
  selectAddress: 'Select address',
  createdMin: 'Date from',
  createdMax: 'Date to',
  basicInfo: 'Basic info',
  fromCreated: 'From created',
  toCreated: 'To created',
  economicCode: 'Economic code',
  lastName: 'Last name',
  mobile: 'Mobile',
  youLogouted: 'You have been logged out',
  done: 'Done',
  status: 'Status',
  canceled: 'Canceled',
  clock: 'Clock',
  createdDate: 'Created Date',
  name: 'Name',
  nationalCode: 'National code',
  nationalId: 'National id',
  networkError: 'Network error',
  no: 'No',
  noRecords: 'No records',
  password: 'Password',
  phone: 'Phone',
  plate: 'Plate',
  return: 'Return',
  save: 'Save',
  settings: 'Settings',
  title: 'Title',
  unit: 'Unit',
  username: 'Username',
  verified: 'Verified',
  view: 'View',
  warning: 'Warning',
  sms: 'SMS',
  yes: 'Yes',
  type: 'Type',
  oldPassword: 'Old password',
  otpSendedToYourMobile: 'OTP sended to your mobile',
  newPassword: 'New password',
  forLoginEnterYourUsername: 'For login enter your username',
  forLoginEnterYourEmail: 'For login enter your email',
  code: 'Code',
  pageNotFound: 'Page not found',
  tryAgain: 'Try again',
  returnHome: 'Return to home',
  permissions: 'Permissions',
  toResendAgain: 'To resend again',
  enterYourMobileNumber: 'Enter your mobile number',
  hello: 'Hello',
  editMobile: 'Edit mobile',
  location: 'Location',
  exit: 'Exit',
  day: 'Day',
  country: 'Country',
  fullName: 'Full name',
  province: 'Province',
  male: 'Male',
  female: 'Female',
  city: 'City',
  district: 'District',
  month: 'Month',
  week: 'Week',
  times: 'Times',
  year: 'Year',
  android: 'Android',
  web: 'Web',
  details: 'Details',
  ios: 'Ios',
  sortOrder: 'Sort order'
}
