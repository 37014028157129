export default {
  checked: 'Checked',
  alertTypeInfo: {
    UV_DOSE: 'UV Dose',
    SUN_SCREEN: 'Sun Screen'
  },
  UV_DOSE: {
    option1: 'I will go under shade',
    option2: 'I will use sunscreen',
    option3: 'I will change my location as soon as possible',
    untouched: 'Unseen'
  },
  SUN_SCREEN: {
    option1: 'I will not renew the Sunscreen',
    option2: 'I will renew the Sunscreen',
    option3: 'I will change my location as soon as possible',
    untouched: 'Unseen'
  }
}
