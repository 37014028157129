export default {
  couponRemoved: 'Coupon removed',
  couponUpdated: 'Coupon updated',
  couponAdded: 'Coupon added',
  coupon: 'Coupon',
  used: 'Used',
  pin: 'Pin',
  availableFrom: 'Available from',
  availableUntil: 'Available until',
  reusable: 'Reusable',
  discountType: 'Discount type',
  claimLimit: 'Claim limit'
}
