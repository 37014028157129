import roles from './roles'
import users from './users'
import workingSites from './workingSites'
import alerts from './alerts'
import devices from './devices'
import tickets from './tickets'
import health from './health'
import registerRequests from './registerRequests'

export default {
  name: 'org',
  path: `/org`,
  meta: {label: 'home', auth: true},
  component: {
    render: (h) => h('router-view')
  },
  children: [roles, users, workingSites, alerts, devices, tickets, registerRequests, health]
}
