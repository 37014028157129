const name = 'orgAlerts'
const path = 'alerts'
export default {
  name: name,
  path: `${path}`,
  meta: {label: name, auth: ['org.alert.operation']},
  redirect: `${path}/index`,
  component: {
    render: (h) => h('router-view')
  },
  children: [
    {
      name: `${name}Index`,
      path: 'index',
      meta: {label: 'index', auth: ['org.alert.operation'], title: 'index'},
      component: () => import('@/pages/ORG/alerts/index')
    },
    {
      name: `${name}View`,
      path: 'view/:id',
      meta: {label: 'view', auth: ['org.alert.operation'], title: 'view'},
      props: true,
      component: () => import('@/pages/ORG/alerts/View')
    }
  ]
}
