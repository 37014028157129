export default {
  paymentConfigRemoved: 'Payment config removed',
  paymentConfigUpdated: 'Payment config updated',
  paymentConfigAdded: 'Payment config added',
  paymentConfig: 'Payment config',
  PASARGAD_IPG: 'Pasargad IPG',
  CASH: 'Cash',
  MELLAT_IPG: 'Mellat IPG',
  PARSIAN_IPG: 'Parsian IPG',
  ZARRINPAL_IPG: 'Zarinpal IPG',
  SAMAN_IPG: 'Saman IPG',
  KIPO_IPG: 'Kipo IPG',
  ISIRANCREDIT_IPG: 'Isirancredit IPG',
  ISIRANDEBIT_IPG: 'Isirandebit IPG',
  PAYPOD_IPG: 'Paypod IPG',
  CAFE_BAZAR_IPG: 'Cafe Bazar IPG',
  VANDAR_IPG: 'Vandar IPG',
  SEPPAYMENT_MPG: 'Seppayment MPG',
  PASARGAD_POS: 'Pasargad POS',
  PARSIAN_POS: 'Parsian POS',
  SAMAN_POS: 'Saman POS',
  IPG: 'IPG',
  MPG: 'MPG',
  POS: 'POS',
  ORG: 'Org',
  handlerType: 'Handler type',
  payType: 'Pay type',
  bankAccount: 'Bank account',
  minimumPayable: 'Minimum payable',
  merchantId: 'Merchant id'
}
