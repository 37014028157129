<template>
  <v-btn small color="success" v-on:click="generate" v-bind="$attrs">
    <v-icon small class="me-1"> mdi-download </v-icon>
    {{ $t('shared.exportExcel') }}
  </v-btn>
</template>

<script>
import {utils, writeFileXLSX} from 'xlsx'
import {isObject} from '@/lib/utils'
export default {
  name: 'ExportExcel',
  inheritAttrs: false,
  props: {
    columns: {type: Array, default: () => []},
    fetch: {type: Function, default: () => Promise.resolve([])},
    fileName: {type: String, default: 'export'}
  },
  methods: {
    async generate() {
      const header = this.columns.map((item) => item.title)
      const rows = [header]

      const items = await this.fetch()
      if (this.$isEmpty(items)) {
        this.$showError(this.$t('shared.noRecordToExport'))
        return
      }

      items.forEach((item) => {
        const row = this.columns.map((column) => {
          let value = this.getNestedValue(item, column.field)
          if (column.formatter) {
            value = column.formatter(value)
          }
          return value
        })
        rows.push(row)
      })

      const wb = utils.book_new()
      const ws = utils.aoa_to_sheet(rows)
      utils.book_append_sheet(wb, ws, 'Sheet1')

      const now = new Date().toISOString()
      writeFileXLSX(wb, `${this.fileName}_${now}.xlsx`)
    },
    getNestedValue(obj, path) {
      if (!path) return obj
      if (!obj) return null

      return path.split('.').reduce((object, key) => {
        if (!isObject(object)) return null
        return object[key]
      }, obj)
    }
  }
}
</script>
