<template>
  <div class="d-flex justify-space-between">
    <div v-if="hideEdit"></div>
    <v-btn v-else color="success" @click="emitSubmit" :loading="loading" :disabled="disabled">
      <v-icon v-if="icon"> {{ icon }} </v-icon>
      <span class="ps-1"> {{ btnText }} </span>
    </v-btn>

    <v-btn class="secondary" @click="back">
      {{ $t('shared.return') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
  props: {
    edit: {type: Boolean, default: false},
    loading: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    hideEdit: {type: Boolean, default: false},
    icon: {
      type: String,
      default() {
        return this.edit ? 'mdi-check' : 'mdi-plus'
      }
    },
    btnText: {
      type: String,
      default() {
        return this.edit ? this.$t('shared.save') : this.$t('shared.add')
      }
    }
  },
  methods: {
    emitSubmit() {
      this.$emit('submit')
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>
