<template>
  <v-expansion-panels v-model="show" class="mb-4" focusable>
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t('shared.search') }}
        <template v-slot:actions>
          <v-icon color="primary"> $expand </v-icon>
        </template>
      </v-expansion-panel-header>

      <v-expansion-panel-content class="mx-n4 pt-2" eager>
        <slot></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'SearchForm',
  props: {
    visible: {type: Boolean, default: false}
  },
  data() {
    return {
      show: this.visible ? 0 : null
    }
  }
}
</script>
