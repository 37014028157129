<template>
	<c-input v-model="model" v-bind="$attrs" @input="emitModel" :suffix="getSuffix" :dir="dir">
		<template v-if="menuItems && menuItems.length" #append-outer>
			<slot>
				<v-menu offset-y>
					<template v-slot:activator="{on, attrs}">
						<v-btn small v-bind="attrs" v-on="on">
							{{ append && append.text }}
							<v-icon dense> mdi-menu-down </v-icon>
						</v-btn>
					</template>

					<v-list dense>
						<v-list-item-group v-model="append" @change="emitAppend">
							<v-list-item v-for="(item, index) in menuItems" :key="index" :value="item">
								<v-list-item-title>{{ item.text }}</v-list-item-title>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-menu>
			</slot>
		</template>
	</c-input>
</template>

<script>
export default {
	name: 'NumericEditor',
	inheritAttrs: false,
	props: {
		value: {type: [String, Number], default: null},
		suffix: {type: [String, Number], default: null},
		dir: {type: String, default: 'ltr'},
		localeString: {type: Boolean, default: true},
		currency: Boolean,
		menu: [String, Number],
		menuItems: {type: Array, default: () => []}
	},
	data() {
		return {
			model: this.getDisplayModel(this.value)
		}
	},
	computed: {
		append: {
			get() {
				return this.menuItems.find((i) => i.value === this.menu)
			},
			set(item) {
				this.$emit('update:menu', item.value)
			}
		},
		getSuffix() {
			if (this.currency || (this.append && this.append.value === 'AMOUNT')) {
				return this.$t('shared.IRT')
			}
			return this.suffix
		}
	},
	methods: {
		emitAppend() {
			this.$nextTick(() => {
				this.model = this.getDisplayModel(this.value)
			})
		},
		emitModel(val) {
			if (!val && val !== 0) {
				return this.emitInput(null)
			}
			val = this.getNumericModel(val)
			if (this.currency || (this.append && this.append.value === 'AMOUNT')) {
				val = val * 10
			}
			this.emitInput(val)
		},
		getDisplayModel(val) {
			if (!val && val !== 0) return null
			val = this.getNumericModel(val)
			if (val === null) return null
			if (this.currency || (this.append && this.append.value === 'AMOUNT')) {
				val = val / 10
			}
			if (!this.localeString) return val
			return val.toLocaleString()
		},
		getNumericModel(val) {
			val = String(val)
			const regex = /[^\d]/g
			val = val.replace(regex, '')
			if (!val) return null
			return +val
		},
		emitInput(val) {
			this.$emit('input', val)
			this.$nextTick(() => {
				this.model = this.getDisplayModel(val)
			})
		}
	}
}
</script>
