<template>
  <img :src="url" v-bind="$attrs" v-on="$listeners" class="vertical-middle" />
</template>

<script>
export default {
  name: 'Thumbnail',
  inheritAttrs: false,
  props: {
    src: {type: String, default: null}
  },
  computed: {
    url() {
      if (!this.src) {
        return null
      }
      return `${this.$config.staticServerUrl}/files/${this.src}`
    }
  }
}
</script>
