<template>
  <numeric-display :value="amount" v-bind="$attrs" :percent="percent" :type="type" :append="getAppend" />
</template>

<script>
import NumericDisplay from '@/components/base/NumericDisplay'
export default {
  name: 'CurrencyDisplay',
  inheritAttrs: false,
  components: {
    NumericDisplay
  },
  props: {
    value: [String, Number],
    percent: Boolean,
    type: String,
    append: String
  },
  computed: {
    amount() {
      if (!this.value && this.value !== 0) return null
      let val = +this.value
      if (isNaN(val)) return null
      if (this.percent || this.type === 'PERCENT') return val
      val = val / this.$config.displayCurrency.rate
      if (this.localeString) val = val.toLocaleString()
      return val
    },
    getAppend() {
      if (this.percent || this.type === 'PERCENT') return this.$t('shared.percent')
      else if (this.append) return this.append
      return this.$config.displayCurrency.text
    }
  }
}
</script>
