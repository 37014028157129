<template>
  <span>
    <span v-if="label" class="text-no-wrap"> {{ label }} : </span>
    <span v-if="value || value === 0">
      <span v-if="prepend"> {{ prepend }} </span>
      <span> {{ amount }} </span>
      <span v-if="getAppend"> {{ getAppend }} </span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'NumericDisplay',
  props: {
    value: [String, Number],
    label: String,
    labelClass: [String, Array, Object],
    percent: Boolean,
    type: String,
    localeString: {type: Boolean, default: true},
    append: String,
    prepend: String
  },
  computed: {
    amount() {
      if (!this.value && this.value !== 0) return null
      let val = +this.value
      if (isNaN(val)) return null
      if (this.percent || this.type === 'PERCENT') return val
      if (this.localeString) val = val.toLocaleString()
      return val
    },
    getAppend() {
      if (this.percent || this.type === 'PERCENT') return this.$t('shared.percent')
      return this.append
    }
  }
}
</script>
