export default {
  mobileConfirmed: 'Mobile confirmed',
  userRemoved: 'User removed',
  userUpdated: 'User updated',
  userAdded: 'User added',
  user: 'User',
  gender: 'Gender',
  birthDate: 'Birth data',
  twoFactorEnabled: 'Two factor enabled',
  roles: 'Roles',
  password: 'Password',
  skinType: 'Skin type',
  userRoles: '{name} roles',
  passwordConfirm: 'Password confirm',
  userAddedToOrganization: 'User added to organization',
  userAddedToWorkingSite: 'User added to working site',
  rolesInScope: 'Roles in {scope}',
  editUserRoles: 'Edit user roles',
  addNewUser: 'Add new user',
  userRolesUpdated: 'User roles updated',
  userRemoveFromWorkingSite: 'User removed from working site',
  userRemoveFromOrganization: 'User removed from organization'
}
