<template>
  <span v-if="value" dir="ltr"> {{ humanizedDate }} </span>
</template>

<script>
import {toZone} from '@/lib/utils'
export default {
  name: 'DateDisplay',
  props: {
    value: {type: String, default: null},
    time: {type: Boolean, default: true},
    format: {
      type: String,
      default() {
        return this.time ? 'YYYY/MM/DD - HH:mm:ss' : 'YYYY/MM/DD'
      }
    }
  },
  computed: {
    humanizedDate() {
      return toZone(this.value, this.format)
    }
  }
}
</script>
