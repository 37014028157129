import ApiBase from './api-base'

export default class Users extends ApiBase {
  constructor(store) {
    const path = 'admin/users'
    super(path, store)
  }
  getRoles(id, params = {}) {
    return this._request({
      action: `${encodeURIComponent(id)}/roles`,
      method: 'GET',
      params
    })
  }

  updateRoles(body) {
    return this._request({
      action: 'roles/manipulate',
      method: 'PATCH',
      body
    })
  }

  addToOrganization(body) {
    return this._request({
      action: 'organizations/add',
      method: 'PATCH',
      body
    })
  }

  addToWorkingSite(body) {
    return this._request({
      action: 'working-sites/add',
      method: 'PATCH',
      body
    })
  }
  removeFromOrganization(body) {
    return this._request({
      action: 'organizations/remove',
      method: 'PATCH',
      body
    })
  }

  removeFromWorkingSite(body) {
    return this._request({
      action: 'working-sites/remove',
      method: 'PATCH',
      body
    })
  }
}
