import api from '@/api'
import store from '@/store'

export default {
  install(Vue) {
    Object.defineProperties(Vue.prototype, {
      $api: {
        get() {
          return api(store)
        }
      }
    })
  }
}
