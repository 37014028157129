export default {
  home: '@:branding.appTitle',
  add: 'Add',
  alerts: 'Alerts',
  branches: 'Branches',
  changePassword: 'Change Password',
  coupons: 'Coupons',
  view: 'View',
  dashboard: 'Dashboard',
  devices: 'Devices',
  edit: 'Edit',
  index: 'Index',
  notifications: 'Notifications',
  onlineUsers: 'Online Users',
  orgAlerts: 'Alerts',
  organizations: 'Organizations',
  orgDevices: 'Devices',
  orgRoles: 'Roles',
  orgUsers: 'Users',
  health: 'Health',
  orgTickets: 'Tickets',
  orgWorkingSites: 'Working Sites',
  paymentConfigs: 'Payment Configs',
  payments: 'Payments',
  profile: 'Profile',
  realms: 'Realms',
  registerRequests: 'Register requests',
  orgRegisterRequests: 'Register requests',
  reports: 'Reports',
  roles: 'Roles',
  tickets: 'Tickets',
  users: 'Users',
  usersActivity: 'Users Activity',
  usersNotifications: 'Users Notifications',
  uv: 'UV',
  workingSites: 'Working Sites'
}
