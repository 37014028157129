export default {
  paymentRemoved: 'Payment removed',
  paymentUpdated: 'Payment updated',
  paymentAdded: 'Payment added',
  payments: 'Payments',
  payment: 'Payment',
  verified: 'Verified',
  payDate: 'Pay date',
  verifiedDate: 'Verified date',
}
