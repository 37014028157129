<template>
  <v-overlay :value="loading" :absolute="absolute">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'PageLoading',
  props: {
    loading: {type: Boolean, default: false},
    absolute: {type: Boolean, default: false}
  }
}
</script>
