<template>
  <div>
    <input type="file" class="d-none" ref="file" @input="upload" :accept="accept" v-bind="$attrs" :multiple="multiple" />

    <div class="mb-2">{{ label }}</div>
    <div class="d-inline-flex">
      <FilesDisplay :value="pathFiles" @remove="removeItem" removable />

      <label v-if="multiple || $isEmpty(pathFiles)" class="mb-0 cursor-pointer" @click="openBrowser">
        <v-card class="d-inline-block me-2 h-100 d-flex align-center justify-center" width="80" height="80" elevation="2">
          <v-progress-circular v-if="progressing" indeterminate color="primary" size="34" />
          <v-icon v-else color="success" size="34"> mdi-cloud-upload </v-icon>
        </v-card>
      </label>
    </div>
  </div>
</template>

<script>
import FilesDisplay from './FilesDisplay'
export default {
  name: 'Uploader',
  inheritAttrs: false,
  components: {FilesDisplay},
  props: {
    value: {type: String, default: null},
    multiple: Boolean,
    label: String,
    accept: {type: String, default: '.jpeg,.jpg,.png,.gif'}
  },
  data() {
    return {
      progressing: false,
      pathFiles: []
    }
  },
  watch: {
    value: {
      handler() {
        this.init()
      },
      immediate: true
    }
  },
  methods: {
    openBrowser() {
      if (this.progressing || this.disabled) return
      this.$refs.file.click()
    },
    init() {
      if (this.value) {
        this.pathFiles = this.value.split(',')
      } else {
        this.pathFiles = []
      }
    },
    removeItem(path) {
      console.log(path)
      this.pathFiles = this.pathFiles.filter((item) => item !== path)
      this.emitInput()
    },
    async upload(e) {
      let files = [...e.target.files]
      if (this.$isEmpty(files)) {
        return
      }

      if (!Array.isArray(files)) {
        files = [files]
      }

      const model = new FormData()
      files.forEach((file) => {
        model.append('files', file)
      })

      try {
        this.progressing = true
        const res = await this.$api.files.upload(model)
        this.pathFiles.push(...res)
        this.emitInput()
      } catch (error) {
        this.$showError(error)
      } finally {
        this.progressing = false
      }
    },
    emitInput() {
      const val = this.pathFiles.join(',')
      this.$emit('input', val)
    }
  }
}
</script>
