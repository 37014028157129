import Vue from 'vue'
import Vuex from 'vuex'

import {setItem, remove} from '@/lib/local-storage'
import {isEmpty} from '../lib/utils'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    $breadcrumbs: [],
    $userInfo: null,
    loading: false,
    $offline: false,
    $account: null,
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    currentOrgId: null
  },
  getters: {
    userOrganizations(state) {
      if (!state.$account) return []
      return state.$account.organizations || []
    },
    currentOrg(state) {
      const {$account} = state
      if (!$account) return null
      if (isEmpty($account.organizations)) {
        return null
      }
      return $account.organizations.find((org) => org.id === state.currentOrgId) || null
    },
    userInfo(state) {
      return state.$userInfo
    },
    permissions(state, getters) {
      const {$account} = state
      if (isEmpty($account)) {
        return []
      } else if (isEmpty($account.organizations)) {
        return $account.permissions || []
      } else if (!isEmpty(getters.currentOrg)) {
        return getters.currentOrg.permissions || []
      }
      return []
    },
    loading(state) {
      return state.loading
    },
    drawer(state) {
      return state.drawer
    },
    userFullName(state) {
      const account = state.$account?.account
      if (!account) return ''
      const fullName = `${account.firstName} ${account.lastName}`
      return account.fullName || fullName
    },
    userId(state) {
      return state.$account?.account?.id
    }
  },
  mutations: {
    setBreadcrumbs(state, breadcrumbs) {
      state.$breadcrumbs = breadcrumbs
    },
    setUserInfo(state, userInfo) {
      state.$userInfo = userInfo
    },
    UPDATE_ACCOUNT({$account}, {user}) {
      $account.firstName = user.firstName
      $account.lastName = user.lastName
      $account.fullName = `${user.firstName || ''} ${user.lastName || ''}`
      this.commit('SET_ACCOUNT', $account)
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    SET_ORG_ID(state, orgId) {
      state.currentOrgId = orgId
      setItem('ORG_ID', orgId)
    },
    SET_ACCOUNT(state, payload) {
      if (!state.currentOrgId) {
        if (isEmpty(payload.organizations)) {
          this.commit('SET_ORG_ID', null)
        } else {
          this.commit('SET_ORG_ID', payload.organizations[0].id)
        }
      }

      state.$account = payload
      setItem('ACCOUNT', payload)
    },
    SET_OFFLINE(state) {
      state.$offline = true
    },
    LOGOUT(state) {
      if (state.$account) {
        state.$account = null
        state.currentOrgId = null
        remove('ORG_ID')
        remove('ACCOUNT')
      }
    },
    SET_LOCALE: (state, {value, i18n}) => {
      state.$locale = value

      i18n.locale = value
      setItem('LOCALE', value)
    }
  },
  actions: {}
})
