export default {
  homeUrl: '/dashboard',
  defaultLocation: {lat: -33.875, lng: 151.04},
  locale: 'en',
  tableFooterProps: {
    itemsPerPageOptions: [20, 50, 100]
  },
  timeZone: {
    name: 'Australia/Sydney',
    symbol: 'UTC'
  },
  localeConfig: {
    en: {
      tz: 'UTC',
      displayFormat: 'YYYY/MM/DD',
      lang: {label: 'Gregorian'}
    }
    // fa: {
    //   tz: 'Asia/Tehran',
    //   displayFormat: 'jYYYY/jMM/jDD',
    //   lang: {label: 'شمسی'}
    // }
  },
  baseCurrency: {
    name: 'IRR',
    text: 'ریال',
    rate: 1
  },
  displayCurrency: {
    name: 'IRT',
    text: 'تومان',
    rate: 10
  },
  get protocol() {
    return this.ssl ? 'https://' : 'http://'
  },
  get apiServerUrl() {
    return `${this.protocol}${this.apiServer}`
  },
  get websiteUrl() {
    return `${this.protocol}${this.website}`
  },
  get staticServerUrl() {
    return `${this.protocol}${this.staticServer}`
  }
}
