import axios from 'axios'
import Qs from 'qs'
import config from '@/config'
import {getItem} from '@/lib/local-storage'

export default class ApiCore {
  constructor(path, store) {
    this._baseUrl = this.getBaseUrl()
    this._path = path
    this._store = store
  }
  getBaseUrl() {
    return config.apiServerUrl + '/api'
  }
  _logout() {
    this._store.commit('LOGOUT')
  }
  _request(o) {
    const orgId = getItem('ORG_ID')
    const path = this._path.replace('/{orgId}', o.orgLess ? '' : `/${orgId}`)
    let url = [this._baseUrl, path, o.action].filter(Boolean).join('/')
    o.headers = o.headers || {}
    const req = {
      url,
      method: o.method,
      params: o.params,
      paramsSerializer: (params) => Qs.stringify(params, {arrayFormat: 'repeat'}),
      data: o.body,
      headers: {
        ...o.headers,
        'Content-Type': 'application/json'
      },
      onUploadProgress: function () {},

      onDownloadProgress: function () {}
    }

    const account = this._store.state.$account

    if (account) {
      req.headers.Authorization = 'Bearer ' + account.accessToken
    }
    return axios(req)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        if (err.message === 'Network Error') {
          this._store.commit('SET_OFFLINE')
          return
        }
        if (err.response.status == 401 || err.response.status == 403 || err.response.status == 406) {
          this._logout()
        }
        throw err.response
      })
  }
}
