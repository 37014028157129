const name = 'tickets'
const path = 'tickets'
export default {
  name: name,
  path: `/${path}`,
  meta: {label: name, auth: ['message.operation']},
  redirect: `/${path}/index`,
  component: {
    render: (h) => h('router-view')
  },
  children: [
    {
      name: `${name}Index`,
      path: 'index',
      meta: {label: 'index', auth: ['message.operation'], title: 'index'},
      component: () => import('@/pages/tickets/index')
    },
    {
      name: `${name}Edit`,
      path: 'view/:id',
      meta: {label: 'view', auth: ['message.operation'], title: 'view'},
      props: true,
      component: () => import('@/pages/tickets/Edit')
    }
  ]
}
