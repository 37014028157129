export default {
  organizations: {
    organizationRemoved: 'سازمان حذف شد',
    organizationUpdated: 'سازمان به روز رسانی شد',
    organizationAdded: 'سازمان افزوده شد',
    organizations: 'سازمان ها',
    organization: 'سازمان',
    organizationId: 'شناسه سازمان',
    fax: 'فکس',
    chooseOrganization: 'انتخاب سازمان',
    editOrgInfo: 'ویرایش اطلاعات سازمان'
  }
}
