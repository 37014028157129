export default {
  registerRequestRemoved: 'Register request removed',
  registerRequestUpdated: 'Register request updated',
  registerRequestAdded: 'Register request added',
  registerRequests: 'Register requests',
  registerRequest: 'Register request',
  registerRequestId: 'Register request ID',
  registerRequestAccepted: 'Register request accepted',
  registerRequestRejected: 'Register request rejected',
  status: {
    PROCESSING: 'Processing',
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected'
  }
}
