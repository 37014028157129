import ApiBase from './api-base'

export default class Devices extends ApiBase {
  constructor(store) {
    const path = 'admin/devices'
    super(path, store)
  }
  assignToUser(body) {
    return this._request({
      action: 'user/assign',
      method: 'POST',
      body
    })
  }
  unassign(id) {
    return this._request({
      action: `user/unassign/${id}`,
      method: 'PUT'
    })
  }
}
