export default {
  // main vee validate rules
  alpha_spaces: '{_field_} Can only contain letter A-Z as well as space',
  digits: '{_field_} Must contain numbers and exactly {length} characters',
  email: 'Email format is not correct',
  image: '{_field_} Must be an image',
  max: '{_field_} must be at most {length} character(s)',
  min: '{_field_} must be at least {length} character(s)',
  numeric: '{_field_} Must be Latin number(s)',
  required: '{_field_} is required',
  regex: '{_field_} is not correct',
  confirmed: '{_field_} is not confirmed',
  alpha_dash: '{_field_} Can only contain letter A-Z, number 0-9, dash and underscore',

  // custom rules
  between: 'must {min} to {max}',
  betweenDate: '{_field_} must be between {min} and {max}',
  betweenTime: '{_field_} must be bewteen {min} and {max}',
  biggerThan: '{_field_} must be more than {number}',
  faChar: 'Must persian character',
  isDuplicate: 'is duplicate',
  isInvalid: '{_field_} is not valid',
  lessThan: '{_field_} must be less than {number}',
  lowercase: 'at least one lowercase letter',
  maxDate: '{_field_} must be before {min}',
  maxDecimal: '{_field_} must have up to {decimal} decimal places',
  maxTime: '{_field_} must be less than {max}',
  max_val: '{_field_} must be less than {max}',
  min_val: '{_field_} must be greater than {min}',
  minDate: '{_field_} must be after {min}',
  minDecimal: '{_field_} must have atleast {decimal} decimal places',
  minTime: '{_field_} must be bigger than {min}',
  mobile: '{_field_} is not correct',
  mustBeExclude: '{_field_} must be exclude {ref}',
  uniqueChars: 'at least  {uniqeChars} unique character(s)',
  uppercase: 'at least one uppercase letter',
  urlCheck: 'Entered url for the {_field_} is not acceptable ',
  invalid: '{_field_} is not valid',
  domainExist: 'This {_field_} has already been taken'
}
