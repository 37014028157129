export default {
  checked: 'Checked',
  priority: 'Priority',
  seen: 'Seen',
  alertType: 'Alert type',
  content: 'Content',
  subject: 'Subject',
  check: 'Check',
  response: 'Response',
  uvDose: {
    option1: 'I will go under shade',
    option2: 'I will use sunscreen',
    option3: 'I will change my location as soon as possible',
    untouched: 'Unseen'
  },
  sunScreen: {
    option1: 'I will not renew the Sunscreen',
    option2: 'I will renew the Sunscreen',
    option3: 'I will change my location as soon as possible',
    untouched: 'Unseen'
  }
}
