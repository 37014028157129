export default {
  home: '@:branding.appTitle',
  users: 'کاربران',
  index: 'لیست',
  dashboard: 'داشبورد',
  add: 'افزودن',
  edit: 'ویرایش',
  profile: 'پروفایل',
  changePassword: 'تغییر رمز عبور',
  roles: 'نقش ها',
  coupons: 'کوپن ها',
  payments: 'پرداخت ها',
  realms: 'حوزه ها',
  branches: 'شعبه ها',
  health: 'پزشکی',
  alerts: 'هشدارها',
  organizations: 'سازمان ها',
  workingSites: 'سایت های کاری',
  devices: 'دستگاه ها',
  onlineUsers: 'کاربران آنلاین',
  paymentConfigs: 'روش های پرداخت',
  reports: 'گزارشات',
  registerRequests: 'درخواست های ثبت نام',
  orgRegisterRequests: 'درخواست های ثبت نام',
  uv: 'UV',
  usersActivity: 'فعالیت کاربران',
  usersNotifications: 'اعلان های کاربران',
  notifications: 'اعلان ها'
}
