export default {
  admin: 'Admin',
  profile: 'Profile',
  logout: 'Logout',
  login: 'Login',
  createAccount: 'Create Account',
  loginNow: 'Login now',
  getStartWithApp: 'Get Started With @:(branding.appTitle)',
  loginTOYourAccount: 'Login to your account',
  signInToYourAccount: 'Sign in to your account',
  forgetPassword: 'Forget password',
  dontHaveAnAccount: "Don't have an account?",
  JoinFreeToday: 'Join free today',
  signup: 'Signup',
  enterYourEmailOrMobile: 'Enter your email or mobile',
  haveAnAccount: 'Have an account?',
  enterYourEmail: 'Enter your email',
  enterYourFirstName: 'Enter your first name',
  enterYourLastName: 'Enter your last name',
  enterYourFullName: 'Enter your full name',
  enterYourMobile: 'Enter your mobile',
  enterYourPassword: 'Enter your password',
  verifyMobileNumber: 'Verify mobile number',
  resetPassword: 'Reset Password',
  enterTheCodeIsSendedToYourMobileOrEmail: 'Enter the code is sended to your mobile or email',
  enterYourPasswordAgain: 'Enter your password again',
  register: 'Register',
  emailOrUsername: 'Email or Username',
  welcomeBack: 'Welcome back',
  forgotPassword: 'Forgot password',
  loginViaOTP: 'Login via OTP',
  loginViaPassword: 'Login via password',
  profileUpdated: 'Profile updated',
  recoveryCodeSendedToYourMobile: 'Recovery code sended to your mobile',
  otpSendedToYourMobile: 'OTP sended to your mobile',
  changePassword: 'Change password',
  resendCode: 'Resend code',
  loginByPassword: 'Login by password',
  sendOtp: 'Send OTP',
  passwordChanged: 'Password changed',
  completeYourProfile: 'Complete your profile',
  submitRegisterRequest: 'Submit register request',
  registrationSended: 'Registration sended'
}
