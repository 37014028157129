export default {
  dashboard: 'داشبورد',
  activityUnderSunTitle: 'نمودار فعالیت در آفتاب به کل فعالیت',
  totalActivity: 'کل فعالیت',
  activityUnderSun: 'فعالیت در آفتاب',
  xPersons: '{x} نفر',
  lastHum: 'آخرین رطوبت',
  lastTemp: 'آخرین دما',
  lastUV: 'آخرین UV',
  uvAverage: 'میانگین UV',
  heatmap: 'heatmap',
  UvAlerts: 'اعلان های UV dose',
  sunScreenNotifications: 'اعلان های sun screen',
  uvDoseAverage: 'میانگین UV dose',
  UVPrediction: 'تخمین UV',
  onlineUv: 'uv لحظه ای',
  start: 'شروع',
  title: 'عنوان',
  percent: 'درصد',
  end: 'پایان',
  lastUVDose: 'آخرین UV Dose',
  uvIndex: 'UV شاخصه',
  uvDose: 'UV Dose',
  userNotificationsTitle: 'اعلان های کاربر',
  onlineUsers: 'کاربران آنلاین'
}
