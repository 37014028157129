<template>
  <span>
    <span v-if="label" class="text-muted"> {{ label }} : </span>
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'CLabel',
  props: {
    label: {type: String, default: null}
  }
}
</script>
