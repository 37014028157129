import development from './development'
import production from './production'
import shared from './shared'

import {extend} from '@/lib/utils'
const config = {
  get development() {
    return extend(shared, development)
  },
  get production() {
    return extend(shared, production)
  }
}
export default config[process.env.NODE_ENV] || config.development
