import ApiBase from './api-base'

export default class Files extends ApiBase {
  constructor(store) {
    const path = 'pub/files/temp'
    super(path, store)
  }

  upload(model) {
    return this._request({
      method: 'POST',
      body: model
    })
  }
}
