import ApiBase from './api-base'

export class RegisterRequests extends ApiBase {
  constructor(store) {
    const path = 'admin/user-reg-requests'
    super(path, store)
  }
}
export class RegisterRequestsPub extends ApiBase {
  constructor(store) {
    const path = 'pub/user-reg-requests'
    super(path, store)
  }
}
