export default {
  500: 'خطای داخلی سرور',
  USERNAME_PASSWORD_NOT_MATCH: 'نام کاربری یا کلمه عبور صحیح نمی باشد',
  USER_NOT_FOUND: 'نام کاربری وارد شده یافت نشد',
  BAD_REQUEST: 'درخواست اشتباه',
  INVALID_DATA_TYPE: 'نام کاربری وارد شده صحیح نمی باشد',
  USERNAME_ALREADY_EXIST: 'نام کاربری تکراری است',
  ILLEGAL_ARGUMENT: 'کد وارد شده صحیح نیست',
  ILLEGAL_REQUEST: 'درخواست مجاز نیست',
  CHILD_RECORD_FOUND: 'حذف امکان پذیر نیست',
  SESSION_EXPIRED: 'زمان نشست منقضی شد',
  VALIDATION_EXCEPTION: 'ورودی معتبر نیست',
  ACCESS_DENIED: 'دسترسی مجاز نیست',
  SERVER_ERROR: 'خطای داخلی سرور',
  DUPLICATE_REQUEST: 'درخواست تکراری است',
  LOGICAL_UN_AUTHORIZED: 'عملیات مجاز نیست',
  USER_NOT_ACTIVE: 'حساب کاربری فعال نیست ',
  MOBILE_NOT_CONFIRM: 'شماره همراه تایید نشده است',
  DATA_NOT_FOUND: 'داده یافت نشد',
  INVALID_TOKEN_HEADER: 'شما از حساب کاربری خود خارج شدید',
  TOKEN_VERIFICATION_EXPIRED: 'نشست شما منقضی شد',

  7077: 'دستگاه اختصاص به کاربر دیگری دارد',
  3101: 'نام کاربری (موبایل/ایمیل) تکراری است',
  27004: 'کاربر عضو شعبه نمی باشد',
  547570: 'سازمان یا سایت کار وجود ندارد',
  36564: 'شما قبلا این درخواست را ثبت کرده اید',
  27009: 'سایت کاری زیرمجموعه سازمان نمی باشد',
  27002: 'سازمان مورد نظر باید شعبه باشد',
  1085: 'موبایل نامعتبر است',
}
