import ORG from './ORG/index'
import addresses from './addresses'
import branches from './branches'
import coupons from './coupons'
import devices from './devices'
import organizations from './organizations'
import paymentConfigs from './paymentConfigs'
import payments from './payments'
import realms from './realms'
import roles from './roles'
import alerts from './alerts'
import users from './users'
import workingSites from './workingSites'
import tickets from './tickets'
import registerRequests from './registerRequests'
import health from './health'

export default [
  ORG,
  {
    name: 'home',
    path: '/',
    meta: {title: 'home', label: 'home', auth: true},
    redirect: '/dashboard',
    component: {
      render: (h) => h('router-view')
    },
    children: [
      {
        name: 'Dashboard',
        path: '/dashboard',
        meta: {
          title: 'dashboard',
          label: 'dashboard',
          auth: 'Idn'
        },
        component: () => import('@/pages/base/Dashboard')
      },
      {
        name: 'Login',
        path: '/login',
        meta: {
          layout: 'empty',
          title: 'login',
          label: 'login',
          auth: true
        },
        component: () => import('@/pages/base/Login')
      },
      {
        name: 'Permissions',
        path: '/permissions',
        meta: {
          layout: 'empty',
          title: 'permissions',
          label: 'permissions',
          auth: 'Idn'
        },
        component: () => import('@/pages/base/Permissions')
      },
      {
        name: 'Profile',
        path: '/profile',
        meta: {
          title: 'profile',
          label: 'profile',
          auth: 'Idn'
        },
        component: () => import('@/pages/base/Profile')
      },
      {
        name: 'LoginByOTP',
        path: '/login-otp',
        meta: {
          layout: 'empty',
          title: 'loginViaOTP',
          label: 'loginViaOTP',
          auth: true
        },
        component: () => import('@/pages/base/LoginOTP')
      },
      {
        name: 'OrgInfo',
        path: '/org-info',
        meta: {
          title: 'OrgInfo',
          label: 'edit',
          auth: ['org.organization.operation']
        },
        component: () => import('@/pages/base/OrgInfo')
      },
      {
        name: 'Register',
        path: '/register',
        meta: {
          layout: 'empty',
          title: 'register',
          label: 'register',
          auth: true
        },
        component: () => import('@/pages/base/Register')
      },
      {
        name: 'ResetPassword',
        path: '/reset-password',
        meta: {
          layout: 'empty',
          title: 'resetPassword',
          label: 'resetPassword',
          auth: true
        },
        component: () => import('@/pages/base/ResetPassword')
      },
      {
        name: 'ChangePassword',
        path: '/change-password',
        meta: {
          title: 'changePassword',
          label: 'changePassword',
          auth: 'Idn'
        },
        component: () => import('@/pages/base/ChangePassword')
      },
      {
        name: 'ForgotPassword',
        path: '/forgot-password',
        meta: {
          layout: 'empty',
          title: 'forgotPassword',
          label: 'forgotPassword',
          auth: true
        },
        component: () => import('@/pages/base/ForgotPassword')
      },
      addresses,
      alerts,
      health,
      registerRequests,
      tickets,
      branches,
      coupons,
      devices,
      organizations,
      paymentConfigs,
      payments,
      realms,
      roles,
      users,
      workingSites
    ]
  },
  {
    name: 'PageNotFound',
    path: '*',
    meta: {
      layout: 'empty',
      title: 'pageNotFound',
      label: 'pageNotFound',
      auth: true
    },
    component: () => import('@/pages/base/404')
  }
]
