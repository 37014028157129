import {Account, AccountPub} from './account'
import Files from './files'
import Branches from './branches'
import Coupons from './coupons'
import Devices from './devices'
import Organizations from './organizations'
import PaymentConfigs from './paymentConfigs'
import Payments from './payments'
import Permissions from './permissions'
import Realms from './realms'
import Reports from './reports'
import Roles from './roles'
import Users from './users'
import workingSites from './workingSites'
import Org from './ORG'
import Addresses from './addresses'
import Alerts from './alerts'
import Health from './health'
import {Tickets, TicketsIdn, TicketMessages} from './tickets'
import {RegisterRequests, RegisterRequestsPub} from './registerRequests'
import MedicalImages from './medicalImages'


export default (store) => ({
  alerts: new Alerts(store),
  health: new Health(store),
  medicalImages: new MedicalImages(store),
  registerRequests: new RegisterRequests(store),
  registerRequestsPub: new RegisterRequestsPub(store),
  ticketMessages: new TicketMessages(store),
  ticketsIdn: new TicketsIdn(store),
  tickets: new Tickets(store),
  account: new Account(store),
  addresses: new Addresses(store),
  accountPub: new AccountPub(store),
  files: new Files(store),
  branches: new Branches(store),
  coupons: new Coupons(store),
  devices: new Devices(store),
  organizations: new Organizations(store),
  paymentConfigs: new PaymentConfigs(store),
  payments: new Payments(store),
  permissions: new Permissions(store),
  realms: new Realms(store),
  reports: new Reports(store),
  roles: new Roles(store),
  users: new Users(store),
  workingSites: new workingSites(store),
  org: Org(store)
})
