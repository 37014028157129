import {getItem} from '@/lib/local-storage'
import {hasPermission} from '@/lib/utils'
import store from '@/store'

export default (to, _from, next) => {
  store.commit('setBreadcrumbs', [])

  let allowed = false
  const orgId = getItem('ORG_ID')
  if (orgId) {
    store.commit('SET_ORG_ID', orgId)
  }

  const account = getItem('ACCOUNT')
  if (account) {
    store.commit('SET_ACCOUNT', account)
  }
  if (to.meta.auth === true) {
    allowed = true
  } else if (!account) {
    allowed = false
  } else if (to.meta.auth === 'Idn') {
    allowed = true
  } else {
    allowed = to.meta.auth.some((i) => hasPermission(i))
  }

  if (allowed) {
    next()
  } else {
    let path = '/login'
    if (to.fullPath) {
      path += `?returnUrl=${to.fullPath}`
    }
    next(path)
  }
}
