import Organizations from './organizations'
import Permissions from './permissions'
import Reports from './reports'
import Roles from './roles'
import Users from './users'
import WorkingSites from './workingSites'
import Alerts from './alerts'
import Devices from './devices'
import Tickets from './tickets'
import {RegisterRequests} from './registerRequests'
import Health from './health'
import MedicalImages from './medicalImages'

export default (store) => ({
  health: new Health(store),
  medicalImages: new MedicalImages(store),
  registerRequests: new RegisterRequests(store),
  organizations: new Organizations(store),
  permissions: new Permissions(store),
  alerts: new Alerts(store),
  tickets: new Tickets(store),
  devices: new Devices(store),
  roles: new Roles(store),
  reports: new Reports(store),
  users: new Users(store),
  workingSites: new WorkingSites(store)
})
