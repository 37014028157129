export default {
  ticketRemoved: 'Ticket removed',
  ticketUpdated: 'Ticket updated',
  ticketAdded: 'Ticket added',
  tickets: 'Tickets',
  ticketInfo: 'Ticket info',
  receiverUser: 'Receiver user',
  senderUser: 'Sender user',
  subject: 'subject',
  sendMessage: 'Send message',
  send: 'Send',
  messageTo: 'Message to',
  text: 'Text',
  messageSended: 'Message sended',
  createTicket: 'Create ticket'
}
