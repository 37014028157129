export default {
  name: 'addresses',
  path: '/addresses',
  meta: {label: 'addresses', auth: true},
  redirect: 'addresses/index',
  component: {
    render: (h) => h('router-view')
  },
  children: [
    {
      name: 'index',
      path: 'index',
      meta: {label: 'index', auth: true, title: 'index'},
      component: () => import('@/pages/addresses/index')
    },
    {
      name: 'create',
      path: 'create',
      meta: {label: 'add', auth: true, title: 'add'},
      component: () => import('@/pages/addresses/Create')
    },
    {
      name: 'edit',
      path: 'edit/:id',
      meta: {label: 'edit', auth: true, title: 'edit'},
      props: true,
      component: () => import('@/pages/addresses/Edit')
    }
  ]
}
