const name = 'health'
const path = 'health'
export default {
  name: name,
  path: `${path}`,
  meta: {label: name, auth: ['org.medical.operation']},
  redirect: `/${path}/index`,
  component: {
    render: (h) => h('router-view')
  },
  children: [
    {
      name: `${name}Index`,
      path: 'index',
      meta: {label: 'index', auth: ['org.medical.operation'], title: 'index'},
      component: () => import('@/pages/ORG/health/index')
    },
    {
      name: `${name}Info`,
      path: 'info/:id',
      meta: {label: 'view', auth: ['org.medical.operation'], title: 'view'},
      props: true,
      component: () => import('@/pages/ORG/health/Info')
    }
  ]
}
