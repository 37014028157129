import Vue from 'vue'
import {i18n} from './i18n'
import {configure, extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {
  digits,
  email,
  max,
  max_value,
  min,
  min_value,
  numeric,
  confirmed,
  required,
  regex,
  alpha_dash,
  between
} from 'vee-validate/dist/rules'

configure({
  defaultMessage: (_field, values) => {
    // values._field_ = lang.t(`fields.${field}`)

    return i18n.t(`validations.${values._rule_}`, values)
  }
})
extend('digits', digits)
extend('between', between)
extend('email', email)
extend('confirmed', confirmed)
extend('max', max)
extend('max_value', max_value)
extend('min', min)
extend('min_value', min_value)
extend('numeric', numeric)
extend('required', required)
extend('regex', regex)
extend('alpha_dash', alpha_dash)

extend('mobile', {
  validate(value, options) {
    const prefix = options[0] || ''
    return !value || `${prefix}${value}`.length === 13
  },
  message(_field_) {
    return i18n.t('validations.isInvalid', {_field_})
  }
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
