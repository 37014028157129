export default {
  admin: 'ناظر',
  profile: 'حساب کاربری',
  logout: 'خروج',
  login: 'ورود',
  signInToYourAccount: 'وارد حساب کاربری خود شوید',
  forgetPassword: 'فراموشی رمز عبور',
  signup: 'ایجاد حساب کاربری',
  register: 'ثبت نام',
  forgotPassword: 'فراموشی رمز عبور',
  loginViaOTP: 'ورود با رمز یکبار مصرف',
  loginViaPassword: 'ورود با رمز عبور',
  resetPassword: 'بازیابی رمز عبور',
  enterYourEmailOrMobile: 'ایمیل یا موبایل خود را وارد کنید',
  haveAnAccount: 'حساب کاربری دارید؟',
  verifyMobileNumber: 'تایید شماره موبایل',
  profileUpdated: 'اطلاعات پروفایل به روز رسانی شد',
  recoveryCodeSendedToYourMobile: 'کد بازیابی رمز عبور به شماره موبایل شما ارسال شد',
  enterTheCodeIsSendedToYourMobileOrEmail: 'کد ارسال شده به موبایل یا ایمیل خود را وارد کنید',
  otpSendedToYourMobile: 'رمز یکبار مصرف به شماره موبایل شما ارسال شد',
  changePassword: 'تغییر رمز عبور',
  resendCode: 'ارسال مجدد کد',
  loginByPassword: 'ورود با رمز عبور',
  sendOtp: 'ارسال رمز یکبار مصرف',
  passwordChanged: 'رمز عبور تغییر کرد',
  completeYourProfile: 'پروفایل خود را تکمیل کنید',
  submitRegisterRequest: 'ثبت درخواست ثبت نام',
  registrationSended: 'درخواست ثبت نام ارسال شد'
}
