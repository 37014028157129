import ApiBase from '../api-base'

export default class Health extends ApiBase {
  constructor(store) {
    const path = 'org/{orgId}/dashboard'
    super(path, store)
  }

  medicalImageRecordDetails(params) {
    return this._request({
      action: 'medical-image-record-details',
      method: 'GET',
      params
    })
  }

  medicalRecordDetails(params) {
    return this._request({
      action: 'medical-record-details',
      method: 'GET',
      params
    })
  }

  medicalRecords(params) {
    return this._request({
      action: 'medical-records',
      method: 'GET',
      params
    })
  }

  medicalUsers(params) {
    return this._request({
      action: 'medical-users',
      method: 'GET',
      params
    })
  }

  medicalUsersCount(params) {
    return this._request({
      action: 'medical-users/count',
      method: 'GET',
      params
    })
  }
}
