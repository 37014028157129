<template>
  <span>
    <span class="black--text text-no-wrap"> {{ label }} : </span>
    <span class="text--secondary">
      <slot></slot>
    </span>
  </span>
</template>

<script>
export default {
  name: 'MLabel',
  props: {
    label: String,
    labelClass: [String, Array, Object]
  }
}
</script>
