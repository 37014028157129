export default {
  paymentRemoved: 'پرداخت حذف شد',
  paymentUpdated: 'پرداخت به روز رسانی شد',
  paymentAdded: 'پرداخت افزوده شد',
  payments: 'پرداخت ها',
  payment: 'پرداخت',
  verified: 'تایید شده',
  payDate: 'تاریخ پرداخت',
  verifiedDate: 'تاریخ تایید',
}
