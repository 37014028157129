import Vue from 'vue'

const requireComponent = require.context(
  '@/components/base', true, /\.vue$/,
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  fileName = fileName.replace('./', '')
  fileName = fileName.replace('.vue', '')

  Vue.component(fileName, componentConfig.default || componentConfig)
})
