import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './sass/fonts/css/index.css'

import {Chart, registerables} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
Chart.register(...registerables, annotationPlugin)

import './plugins/snotify'
import './plugins/base'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import api from './plugins/api'
import mixin from './plugins/mixin'
import Config from './plugins/config'
import i18n from './plugins/i18n'
import JsonExcel from 'vue-json-excel'

Vue.config.productionTip = false
Vue.use(api)
Vue.use(Config)
Vue.use(mixin)
Vue.component('downloadExcel', JsonExcel)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
  // beforeCreate() {
  //   const locale = getItem('LOCALE') || config.locale
  //   this.$store.commit('SET_LOCALE', {
  //     value: locale,
  //     validator: this.$validator,
  //     i18n: this.$i18n
  //   })
  // }
}).$mount('#app')
