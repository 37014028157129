import ApiBase from './api-base'
import 'clientjs'
let client = new ClientJS()

export class AccountPub extends ApiBase {
  constructor(store) {
    const path = 'pub/account'
    super(path, store)
  }
  login(model, scope) {
    let fingerprint = client.getFingerprint()
    model.uniqueId = fingerprint
    model.clientType = 'WEB'
    return this._request({
      action: 'login',
      method: 'POST',
      body: model,
      headers: {scope}
    })
  }

  register(model, scope) {
    let fingerprint = client.getFingerprint()
    model.uniqueId = fingerprint
    model.clientType = 'WEB'
    return this._request({
      action: 'register',
      method: 'POST',
      body: model,
      headers: {scope}
    })
  }

  loginByOtp(model, scope) {
    let fingerprint = client.getFingerprint()
    model.uniqueId = fingerprint
    model.clientType = 'WEB'
    return this._request({
      action: 'verify-otp',
      method: 'POST',
      body: model,
      headers: {scope}
    })
  }

  sendOtp(model) {
    return this._request({
      action: 'send-otp',
      method: 'POST',
      body: model
    })
  }

  forgotPassword(model) {
    return this._request({
      action: 'forgot-password',
      method: 'POST',
      body: model
    })
  }

  resetPassword(model) {
    return this._request({
      action: 'reset-password',
      method: 'POST',
      body: model
    })
  }
}

export class Account extends ApiBase {
  constructor(store) {
    const path = 'idn/account'
    super(path, store)
  }
  logout() {
    return this._request({
      action: 'logout',
      method: 'POST'
    }).catch((err) => {
      if (err.status === 406) {
        return this._logout()
      }
    })
  }

  profile() {
    return this._request({
      action: 'profile',
      method: 'GET'
    })
  }

  updateProfile(body) {
    return this._request({
      action: 'profile',
      method: 'PUT',
      body
    })
  }

  changePassword(body) {
    return this._request({
      action: 'change-password',
      method: 'POST',
      body
    })
  }
}
