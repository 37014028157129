<template>
  <c-input
    auto-complete
    v-model="model"
    v-bind="$attrs"
    :item-text="itemText"
    :item-value="itemValue"
    :items="items"
    :clearable="!multiple"
    :chips="multiple"
    :small-chips="multiple"
    :deletable-chips="multiple"
    :multiple="multiple"
    @change="emitChange"
    hide-selected
    :menu-props="{closeOnContentClick: true}"
    :hide-no-data="progressing"
    :loading="progressing"
    @update:search-input="searchTerm"
    :search-input.sync="term"
    @focus="searchTerm"
  />
</template>

<script>
export default {
  name: 'AutoComplete',
  inheritAttrs: false,
  props: {
    value: {type: [Number, String, Array, Object], default: null},
    select: Function,
    search: Function,
    itemText: {type: String, default: 'name'},
    itemValue: {type: String, default: 'id'},
    multiple: {type: Boolean, default: false},
    filterKey: {
      type: String,
      default() {
        return this.itemText
      }
    }
  },
  data() {
    return {
      initializing: true,
      progressing: false,
      term: null,
      items: []
    }
  },
  async created() {
    await this.setSelected()
    this.$nextTick(() => {
      this.initializing = false
    })
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    emitChange(value) {
      if (!value) {
        this.$emit('change', null)
      }
      const item = this.items.find((i) => {
        return i[this.itemValue] === value
      })
      this.$emit('change', item)
    },
    searchTerm() {
      if (this.initializing) return
      this.$nextTick(() => {
        this.progressing = true
        const filter = {}
        if (this.term) {
          filter[this.filterKey] = this.term
        }
        return this.search(filter).then((items) => {
          this.items = items
          this.progressing = false
        })
      })
    },
    setSelected() {
      if (this.select) {
        return this.select().then((initValue) => {
          if (this.$isEmpty(initValue)) {
            initValue = []
          }
          if (!Array.isArray(initValue)) {
            initValue = [initValue]
          }
          this.items = initValue
          const value = this.multiple ? initValue : initValue[0]
          this.$emit('initialize', value)
        })
      }
    }
  }
}
</script>
