import ApiBase from '../api-base'

export default class Reports extends ApiBase {
  constructor(store) {
    const path = 'org/{orgId}/dashboard'
    super(path, store)
  }

  getGlobalUv(params) {
    return this._request({
      action: 'global-uv',
      method: 'GET',
      params
    })
  }

  getHeatmap(params) {
    return this._request({
      action: 'heatmap-data',
      method: 'GET',
      params
    })
  }

  getUsersActivity(params) {
    return this._request({
      action: 'under-sun-period',
      method: 'GET',
      params
    })
  }

  getAlerts(params) {
    return this._request({
      action: 'alert-option',
      method: 'GET',
      params
    })
  }

  getUsersUv(params) {
    return this._request({
      action: 'uv-with-dose',
      method: 'GET',
      params
    })
  }

  getOnlineUsers(params) {
    return this._request({
      action: 'online-user-data',
      method: 'GET',
      params
    })
  }

  getOnlineUsersAverage(params) {
    return this._request({
      action: 'online-user-data-average',
      method: 'GET',
      params
    })
  }
}
