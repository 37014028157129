export default {
  roleRemoved: 'نقش حذف شد',
  roleUpdated: 'نقش به روز رسانی شد',
  roleAdded: 'نقش افزوده شد',
  permissionsList: 'لیست دسترسی ها',
  roles: 'نقش ها',
  permissions: 'دسترسی ها',
  superAdmin: 'سوپر ادمین',
  type: {
    ADMIN: 'مدیر',
    WORKER: 'کارگر',
    DOCTOR: 'دکتر',
    ORG: 'سازمان',
    USER: 'کاربر'
  }
}
