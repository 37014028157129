<template>
  <div>
    <v-card v-for="item in items" :key="item" class="d-inline-block me-2" elevation="2">
      <a :href="staticUrl(item)" target="_blank" class="d-inline-block pa-1" style="line-height: 0px">
        <img v-if="isImage(item)" alt="image" :src="staticUrl(item)" width="65" height="65" />

        <video v-else-if="isVideo(item)" width="65" height="65">
          <source :src="url(item)" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div v-else>{{ getExtension(item) }}</div>
      </a>

      <v-icon
        v-if="removable"
        color="error"
        class="white rounded elevation-1"
        @click.stop="emitRemove(item)"
        small
        :style="{position: 'absolute', top: '5px', left: '5px'}"
      >
        mdi-close
      </v-icon>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'FileDisplay',
  props: {
    value: {type: [Array, String], default: () => []},
    removable: {type: Boolean, default: false}
  },
  computed: {
    items() {
      if (this.$isEmpty(this.value)) return []
      if (Array.isArray(this.value)) return this.value
      return this.value.split(',')
    }
  },
  methods: {
    staticUrl(path) {
      return `${this.$config.staticServerUrl}/files/${path}`
    },
    isImage(file) {
      const imageExtensions = new Set(['jpeg', 'jpg', 'png', 'gif'])
      return imageExtensions.has(this.getExtension(file))
    },
    isVideo(file) {
      const videoExtensions = new Set(['mp4', 'ogg', 'webm'])
      return videoExtensions.has(this.getExtension(file))
    },
    getExtension(file) {
      return file.split('.').pop().toLowerCase()
    },
    emitRemove(item) {
      this.$emit('remove', item)
    }
  }
}
</script>
