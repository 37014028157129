<template>
  <v-card :style="styles" v-bind="$attrs" v-on="$listeners">
    <helper-offset
      v-if="hasOffset"
      :inline="inline"
      :full-width="fullWidth"
      :offset="offset"
    >
      <v-card
        v-if="!$slots.offset"
        :color="color"
        :elevation="elevation"
        class="d-flex align-center"
        dark
        min-height="70"
      >
        <slot v-if="!title && !text" name="header" />
        <div v-else class="px-5">
          <h3 class="font-weight-regular" v-text="title" />
          <p v-if="text" class="mb-0 mt-2 font-90" v-text="text" />
        </div>
        <slot name="detail"></slot>
      </v-card>

      <slot v-else name="offset" />
    </helper-offset>

    <v-card-text :class="paddingless ? 'pa-0' : 'pt-6 px-8'">
      <slot />
    </v-card-text>

    <v-card-actions v-if="$slots.actions" class="px-6 pb-4 d-block">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
import HelperOffset from './Offset'
export default {
  name: 'MaterialCard',
  inheritAttrs: false,
  components: {
    HelperOffset
  },
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    elevation: {
      type: [Number, String],
      default: 5
    },
    inline: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    paddingless: {
      type: Boolean,
      default: false
    },
    offset: {
      type: [Number, String],
      default: 24
    },
    title: {
      type: String,
      default: undefined
    },
    text: {
      type: String,
      default: undefined
    }
  },

  computed: {
    hasOffset() {
      return this.$slots.header || this.$slots.offset || this.title || this.text
    },
    styles() {
      if (!this.hasOffset) return null

      return {
        marginBottom: `${this.offset}px`,
        marginTop: `${this.offset + 10}px`
      }
    }
  }
}
</script>
