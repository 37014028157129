export default {
  branches: {
    branchRemoved: 'شعبه حذف شد',
    branchUpdated: 'شعبه به روز رسانی شد',
    branchAdded: 'شعبه افزوده شد',
    branches: 'شعبه ها',
    branch: 'شعبه',
    fax: 'فکس',
    chooseBranch: 'انتخاب شعبه',
    editBranchInfo: 'ویرایش اطلاعات شعبه'
  }
}
