export default {
  organizationRemoved: 'Branch removed',
  organizationUpdated: 'Branch updated',
  organizationAdded: 'Branch added',
  branches: 'Branches',
  branch: 'Branch',
  fax: 'Fax',
  chooseBranch: 'Choose branch',
  editOrgInfo: 'Edit branch info'
}
