const name = 'coupons'
const path = 'coupons'
export default {
  name: name,
  path: `/${path}`,
  meta: {label: name, auth: ['financial.coupon.get']},
  redirect: `/${path}/index`,
  component: {
    render: (h) => h('router-view')
  },
  children: [
    {
      name: `${name}Index`,
      path: 'index',
      meta: {label: 'index', auth: ['user-management.edit'], title: 'index'},
      component: () => import('@/pages/coupons/index')
    },
    {
      name: `${name}Create`,
      path: 'create',
      meta: {label: 'add', auth: ['financial.coupon.create'], title: 'add'},
      component: () => import('@/pages/coupons/Create')
    },
    {
      name: `${name}Edit`,
      path: 'edit/:id',
      meta: {label: 'edit', auth: ['user-management.edit'], title: 'edit'},
      props: true,
      component: () => import('@/pages/coupons/Edit')
    }
  ]
}
