import ApiBase from './api-base'

export default class Reports extends ApiBase {
  constructor(store) {
    const path = 'admin/dashboard'
    super(path, store)
  }

  getGlobalUv(params) {
    return this._request({
      action: 'global-uv',
      method: 'GET',
      params
    })
  }

  medicalImageRecordDetails(params) {
    return this._request({
      action: 'medical-image-record-details',
      method: 'GET',
      params
    })
  }

  medicalRecordDetails(params) {
    return this._request({
      action: 'medical-record-details',
      method: 'GET',
      params
    })
  }

  medicalRecords(params) {
    return this._request({
      action: 'medical-records',
      method: 'GET',
      params
    })
  }

  medicalUsers(params) {
    return this._request({
      action: 'medical-users',
      method: 'GET',
      params
    })
  }

  getHeatmap(params) {
    return this._request({
      action: 'heatmap-data',
      method: 'GET',
      params
    })
  }

  getUsersActivity(params) {
    return this._request({
      action: 'under-sun-period',
      method: 'GET',
      params
    })
  }

  getAlerts(params) {
    return this._request({
      action: 'alert-option',
      method: 'GET',
      params
    })
  }

  getUsersUv(params) {
    return this._request({
      action: 'uv-with-dose',
      method: 'GET',
      params
    })
  }

  getOnlineUsers(params) {
    return this._request({
      action: 'online-user-data',
      method: 'GET',
      params
    })
  }

  getOnlineUsersAverage(params) {
    return this._request({
      action: 'online-user-data-average',
      method: 'GET',
      params
    })
  }
}
