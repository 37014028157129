const name = 'orgRegisterRequests'
const path = 'register-requests'
export default {
  name: name,
  path: `${path}`,
  meta: {label: name, auth: ['org.user-register-request.operation']},
  redirect: `${path}/index`,
  component: {
    render: (h) => h('router-view')
  },
  children: [
    {
      name: `${name}Index`,
      path: 'index',
      meta: {label: 'index', auth: ['org.user-register-request.operation'], title: 'index'},
      component: () => import('@/pages/ORG/registerRequests/index')
    }
  ]
}
