export default {
  roleRemoved: 'Role removed',
  roleUpdated: 'Role updated',
  roleAdded: 'Role added',
  permissionsList: 'Permissions list',
  roles: 'Roles',
  permissions: 'Permissions',
  superAdmin: 'Super Admin',
  type: {
    ADMIN: 'Admin',
    WORKER: 'Worker',
    DOCTOR: 'Doctor',
    ORG: 'Organization',
    USER: 'User'
  }
}
